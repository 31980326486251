html{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.is-active {
  color: #031A55 !important;
}
.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
}

ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  line-height: 40px;
  position: relative;
  display: block;
  color: #545050;
  margin: 0px 20px;
  text-decoration: none;
  cursor: pointer;
  transition: 2ms all ease;
}
.header__middle__menus {
  width: 100%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  position: relative;
}
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
  margin-left:65px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  transition: 2ms all ease;
  width: 250px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  border-radius: 3px;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media only screen and (min-width: 1536px) {
  .container{
    max-width: 1450px !important;
  }
}

@media only screen and (min-width:1651px){
  .container{
    max-width: 1520px  !important;
  }
}
@media only screen and (max-width: 1024px) {
  .navbar{
    display: block;
  }
  .main-nav{
    display: none;
  }
  .footer-ul:last-child{
    padding-left: 0px !important;
  }
  .footer-ul:nth-child(3){
    padding-left:0px !important;
  }
 
}
@media only screen and (max-width:500px){
  .slick-slide {
    height: 250px;
  }
  .slick-slide img{
    height: 250px;
    background-size: cover;
  }
}

.menu-bars {
  font-size: 1.5rem;
  background: none;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0px;
}
.close{
  margin-left:200px;
  margin-top: 10px;
  font-size: 21px;
}
.menu-item:nth-child(2){
  margin-right: 20 px;
}

.nav-menu {
  background-color: #031A55;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 100;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text svg{
  margin-right: 10px;
}
.nav-text a:hover {
  background-color:#38586e;
}
.navbar-hidden a:hover{
transition: all;
}
.navbar-hidden a{
  position: relative;
}

.navbar-hidden a:hover::after{
  width: 100%;
}

.nav-menu-items {
  width: 100%;
}

.navlinks {
  list-style: none;
  float: right;
  margin: 0;
  padding: 0;
}
.navlinks li {
  display: inline-block;
  margin: 0px 20px;
  
}
.navlinks li a {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s linear 0s;
  text-transform: uppercase;
  
}
.navlinks li a:hover {
  color: #00243D;
  padding-bottom: 7px;
  border-bottom: 2px solid #00243D;
}
.footer-ul:last-child{
  padding-left: 50px;
}
.footer-ul:nth-child(3){
  padding-left: 20px;
}
li a.contact {
  background-color: #00243D;
  padding: 9px 20px;
  border-radius: 50px;
  transition: all 0.3s ease 0s;
  border-bottom: none;
  
}
li a.contact:hover {
  background-color:#00243D;
  color: white;
  border-bottom: none;
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
  
}
.menu-item:hover > a{
  color: #00243D !important;
}

/*
.hover::after{
  content: "";
  display: none;
  width:100%;
  height:2px;
  transition:all 0.5s;
  background-color:black;
}
.menu-item:hover .hover::after{
  display:block;
}
*/
.menu-item .sub__menus li:hover a{
  color: #031A55 !important;
  transition: 2ms all ease;
  
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  
  padding: 6px 7px;
  border-radius: 5px;
  margin: 15px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}




  
